/**
 * Implement Gatsby's Browser APIs in this file. Test
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
require("./src/dist/css/bootstrap.min.css")
require("./src/dist/css/font-awesome.min.css")
require("./src/dist/css/material-design-iconic-font.min.css")
require("./src/dist/css/global-custom-style.scss")
require("./src/dist/css/plugins.css")

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) { 
    require("intersection-observer")
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}